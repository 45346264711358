import { Button, MenuItem, MenuList, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { eggshell, midnight, theme } from 'theme/Theme';

import { ReactComponent as IconBrandKit } from '../../assets/icn-nft-gaming.svg';
import { ReactComponent as IconAbout } from '../../assets/navsection/icn-about.svg';
import { ReactComponent as IconBlog } from '../../assets/navsection/icn-blog.svg';

const menuItemsStyle = {
  '&.Mui-selected': {
    backgroundColor: eggshell,
  },
  '&.Mui-selected:hover': {
    backgroundColor: eggshell,
  },
  '&:hover': {
    backgroundColor: eggshell,
  },
  borderRadius: '4px',
  fontFamily: 'RotaExtraBold',
  fontSize: '16px',
  height: '40px',
  justifyContent: { sm: 'flex-start', xs: 'start' },
  margin: { sm: '0 12px 0 12px', xs: 0 },
  paddingLeft: '6px !important',
  px: { sm: 0, xs: 8 },
};

type Props = {
  setOpen: (open: boolean) => void;
};

const MENUS = [
  {
    href: '/about',
    icon: <IconAbout width={30} />,
    name: 'About',
  },
  {
    href: '/blog',
    icon: <IconBlog width={30} />,
    name: 'Blog',
  },
  {
    href: '/presskit',
    icon: <IconBrandKit width={30} />,
    name: 'Brand Kit',
  },
];

export const MenuListResources: React.FC<React.PropsWithChildren<Props>> = ({ setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigate = (href: string) => {
    setOpen(false);
    navigate(href);
  };

  return (
    <>
      <MenuList
        sx={{
          borderRadius: '4px',
          fontFamily: 'RotaExtraBold',
          fontSize: '16px',
          paddingBottom: { sm: '1px', xs: '0' },
          paddingTop: { sm: '12px', xs: '0' },
          pb: { sm: 0, xs: 2 },
          width: { sm: '200px', xs: 'unset' },
        }}
      >
        {MENUS.map(menu => (
          <MenuItem
            key={menu.name}
            onClick={() => handleNavigate(menu.href)}
            selected={!isMobile && location.pathname === menu.href}
            sx={menuItemsStyle}
          >
            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={isMobile ? '14px' : '6px'}
              justifyContent={'center'}
            >
              {menu?.icon}
              <Button
                disableRipple
                sx={{
                  '&:hover': {
                    background: eggshell,
                  },
                  background: 'transparent !important',
                  borderRadius: 0,
                  color: midnight,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '16px',
                  height: '100%',
                  justifyContent: { sm: 'flex-start', xs: 'start' },
                  padding: 0,
                  position: 'relative',
                }}
              >
                {menu.name}
              </Button>
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    </>
  );
};
