import arch from '../assets/icn-arch.svg';
import og from '../assets/img-logo-0g.png';
import abracadabra from '../assets/img-logo-abracadabra.svg';
import acala from '../assets/img-logo-acala.svg';
import animoca from '../assets/img-logo-animoca.png';
import aries from '../assets/img-logo-aries.svg';
import astro from '../assets/img-logo-astro.svg';
import bastion from '../assets/img-logo-bastion.svg';
import benqi from '../assets/img-logo-benqi.png';
import bonfida from '../assets/img-logo-bonfida.svg';
import bblock from '../assets/img-logo-bonusblock.png';
import cetus from '../assets/img-logo-cetus.svg';
import coinbase from '../assets/img-logo-coinbase.png';
import cyberconnect from '../assets/img-logo-cyberconnect.svg';
import deltaprime from '../assets/img-logo-deltaprime.svg';
import dopex from '../assets/img-logo-dopex.svg';
import econia from '../assets/img-logo-econia.svg';
import fewcha from '../assets/img-logo-fewcha.svg';
import flask from '../assets/img-logo-flask.svg';
import gmx from '../assets/img-logo-gmx.png';
import gTrade from '../assets/img-logo-gtrade.svg';
import hawksight from '../assets/img-logo-hawksight.svg';
import hedge from '../assets/img-logo-hedge.svg';
import helix from '../assets/img-logo-helix.svg';
import holaplex from '../assets/img-logo-holaplex.svg';
import hubble from '../assets/img-logo-hubble.svg';
import huma from '../assets/img-logo-huma.svg';
import hyperliquid from '../assets/img-logo-hyperliquid.svg';
import hyperspace from '../assets/img-logo-hyperspace-lg.svg';
import injective from '../assets/img-logo-injective.svg';
import keepsake from '../assets/img-logo-keepsake.svg';
import levana from '../assets/img-logo-levana.png';
import lpfinance from '../assets/img-logo-lpfinance.svg';
import magpie from '../assets/img-logo-magpie.png';
import moca from '../assets/img-logo-moca.svg';
import monkedao from '../assets/img-logo-monkedao.svg';
import movement from '../assets/img-logo-movement.png';
import navi from '../assets/img-logo-navi.svg';
import nibiru from '../assets/img-logo-nibiru.png';
import on1force from '../assets/img-logo-on1force.png';
import oneplanet from '../assets/img-logo-oneplanet.png';
import only1 from '../assets/img-logo-only1.svg';
import opendefi from '../assets/img-logo-opendefi.svg';
import opendive from '../assets/img-logo-opendive.svg';
import optifi from '../assets/img-logo-optifi.svg';
import orca from '../assets/img-logo-orca.png';
import osmosis from '../assets/img-logo-osmosis.svg';
import ottersec from '../assets/img-logo-ottersec.svg';
import parrottools from '../assets/img-logo-parrottools.svg';
import plume from '../assets/img-logo-plume.png';
import pocketworlds from '../assets/img-logo-pocketworlds.svg';
import pontem from '../assets/img-logo-pontem.svg';
import port from '../assets/img-logo-port.svg';
import realms from '../assets/img-logo-realms.svg';
import rome from '../assets/img-logo-rome.svg';
import scallop from '../assets/img-logo-scallop.png';
import sharky from '../assets/img-logo-sharky.svg';
import snowflake from '../assets/img-logo-snowflake.svg';
import spaceid from '../assets/img-logo-spaceid.svg';
import struct from '../assets/img-logo-struct.png';
import suins from '../assets/img-logo-suins.png';
import switchboard from '../assets/img-logo-switchboard.svg';
import synfutures from '../assets/img-logo-synfutures.svg';
import synthetix from '../assets/img-logo-synthetix.png';
import tashi from '../assets/img-logo-tashi.svg';
import thala from '../assets/img-logo-thala.png';
import thesolarmy from '../assets/img-logo-thesolarmy.svg';
import topaz from '../assets/img-logo-topaz.svg';
import tradeport from '../assets/img-logo-tradeport.svg';
import tribeca from '../assets/img-logo-tribeca.svg';
import tsunami from '../assets/img-logo-tsunami.svg';
import ubiloan from '../assets/img-logo-ubiloan.png';
import vertex from '../assets/img-logo-vertex.png';
import vestafinance from '../assets/img-logo-vestafinance.svg';
import xion from '../assets/img-logo-xion.svg';
import xmtp from '../assets/img-logo-xmtp.png';
import zebec from '../assets/img-logo-zebec.svg';
import zeroswap from '../assets/img-logo-zeroswap.svg';
import zeta from '../assets/img-logo-zeta.png';
import paribus from '../assets/paribus.png';

type DappInfo = Readonly<{
  name: string;
  logoSrc: string;
  url: string;
}>;

export const DAPP_LOOKUP: { [name: string]: DappInfo } = {
  abracadabra: {
    logoSrc: abracadabra,
    name: 'Abracadabra',
    url: 'https://medium.com/notifi/notifi-partners-with-abracadabra-to-provide-real-time-alerts-for-defi-users-fa62d896ba7e',
  },
  acala: {
    logoSrc: acala,
    name: 'Acala',
    url: 'https://acala.network/',
  },
  animoca: {
    logoSrc: animoca,
    name: 'Animoca Brands',
    url: 'https://medium.com/notifi/animoca-brands-launches-the-mocaverse-nfts-with-notifications-93f4f4c2da58',
  },
  arch: {
    logoSrc: arch,
    name: 'Arch',
    url: 'https://www.arch.network/',
  },
  aries: {
    logoSrc: aries,
    name: 'Aries',
    url: 'https://ariesmarkets.xyz/',
  },
  astro: {
    logoSrc: astro,
    name: 'Astro DAO',
    url: 'https://astrodao.com/',
  },
  bastion: {
    logoSrc: bastion,
    name: 'Bastion',
    url: 'https://app.bastionprotocol.com/',
  },
  benqi: {
    logoSrc: benqi,
    name: 'Benqi',
    url: 'https://benqi.fi/',
  },
  bonfida: {
    logoSrc: bonfida,
    name: 'Bonfida',
    url: 'https://bonfida.org/',
  },
  bonusblock: {
    logoSrc: bblock,
    name: 'Bonus Block',
    url: 'https://medium.com/notifi/notifi-integrates-with-bonusblock-enabling-alerts-for-the-multi-chain-on-chain-engagement-799a2fb988a7',
  },
  cetus: {
    logoSrc: cetus,
    name: 'Cetus',
    url: 'https://www.cetus.zone/',
  },
  coinbase: {
    logoSrc: coinbase,
    name: 'Coinbase',
    url: 'https://www.coinbase.com/en-ca/wallet',
  },
  cyberconnect: {
    logoSrc: cyberconnect,
    name: 'Cyber Connect',
    url: 'https://cyberconnect.me/',
  },
  deltaprime: {
    logoSrc: deltaprime,
    name: 'Delta Prime',
    url: 'https://deltaprime.io/',
  },
  dopex: {
    logoSrc: dopex,
    name: 'Dopex',
    url: 'https://www.dopex.io/',
  },
  econia: {
    logoSrc: econia,
    name: 'Econia',
    url: 'https://www.econia.dev/',
  },
  fewcha: {
    logoSrc: fewcha,
    name: 'Fewcha',
    url: 'https://fewcha.app/',
  },
  flask: {
    logoSrc: flask,
    name: 'Flask',
    url: 'https://www.flask.global/',
  },
  gTrade: {
    logoSrc: gTrade,
    name: 'gTrade',
    url: 'https://gmx.io/#/',
  },
  gmx: {
    logoSrc: gmx,
    name: 'GMX',
    url: 'https://gmx.io/#/',
  },
  hawksight: {
    logoSrc: hawksight,
    name: 'Hawksight',
    url: 'https://hawksight.co/',
  },
  hedge: {
    logoSrc: hedge,
    name: 'Hedge',
    url: 'https://www.hedge.so/',
  },
  helix: {
    logoSrc: helix,
    name: 'Helix',
    url: 'https://helixapp.com/',
  },
  holaplex: {
    logoSrc: holaplex,
    name: 'Holaplex',
    url: 'https://www.holaplex.com/',
  },
  hubble: {
    logoSrc: hubble,
    name: 'Hubble',
    url: 'https://hubbleprotocol.io/',
  },
  huma: {
    logoSrc: huma,
    name: 'Huma',
    url: 'https://huma.finance/',
  },
  hyperliquid: {
    logoSrc: hyperliquid,
    name: 'Hyperliquid',
    url: 'https://medium.com/notifi/hyperliquid-trading-alerts-and-announcements-powered-by-notifi-832f4195bd01',
  },
  hyperspace: {
    logoSrc: hyperspace,
    name: 'Hyperspace',
    url: 'https://hyperspace.xyz/',
  },
  injective: {
    logoSrc: injective,
    name: 'Injective',
    url: 'https://injective.com/',
  },
  keepsake: {
    logoSrc: keepsake,
    name: 'Keepsake',
    url: 'https://keepsake.gg/',
  },
  levana: {
    logoSrc: levana,
    name: 'Levana',
    url: 'https://www.levana.finance/',
  },
  lpfinance: {
    logoSrc: lpfinance,
    name: 'LP Finance',
    url: 'https://www.lp.finance/',
  },
  magpie: {
    logoSrc: magpie,
    name: 'Magpie',
    url: 'https://www.magpiexyz.io/',
  },
  moca: {
    logoSrc: moca,
    name: 'Mocaverse',
    url: 'https://www.mocaverse.xyz/',
  },
  monkedao: {
    logoSrc: monkedao,
    name: 'Monke Dao',
    url: 'https://www.monkedao.io/',
  },
  movement: {
    logoSrc: movement,
    name: 'Movement',
    url: 'https://movementlabs.xyz/',
  },
  navi: {
    logoSrc: navi,
    name: 'Navi',
    url: 'https://www.naviprotocol.io/',
  },
  nibiru: {
    logoSrc: nibiru,
    name: 'Nibiru',
    url: 'https://nibiru.fi/',
  },
  og: {
    logoSrc: og,
    name: 'og',
    url: 'https://0g.ai/',
  },
  on1force: {
    logoSrc: on1force,
    name: 'On1force',
    url: 'https://www.0n1force.com/',
  },
  oneplanet: {
    logoSrc: oneplanet,
    name: 'Oneplanet',
    url: 'https://www.oneplanetnft.io/',
  },
  only1: {
    logoSrc: only1,
    name: 'Only1',
    url: 'https://only1.app/',
  },
  opendefi: {
    logoSrc: opendefi,
    name: 'Open Defi',
    url: 'https://www.opendefi.xyz/',
  },
  opendive: {
    logoSrc: opendive,
    name: 'Open Dive',
    url: 'https://opendive.io/',
  },
  optifi: {
    logoSrc: optifi,
    name: 'Optifi',
    url: 'https://acala.network/',
  },
  orca: {
    logoSrc: orca,
    name: 'Orca',
    url: 'https://www.orca.so/',
  },
  osmosis: {
    logoSrc: osmosis,
    name: 'Osmosis',
    url: 'https://osmosis.zone/',
  },
  ottersec: {
    logoSrc: ottersec,
    name: 'Otter Sec',
    url: 'https://osec.io/',
  },
  paribus: {
    logoSrc: paribus,
    name: 'Paribus',
    url: 'https://medium.com/notifi/notifi-integrates-with-cross-chain-lending-protocol-users-on-paribus-get-real-time-alerts-6d25f94b3758',
  },
  parrottools: {
    logoSrc: parrottools,
    name: 'Parrot Tools',
    url: 'https://www.prosperousparrots.com/',
  },
  plume: {
    logoSrc: plume,
    name: 'Plume',
    url: 'https://medium.com/notifi/unlocking-the-future-of-real-world-assets-with-plume-network-and-notifi-integration-d95876e5c2b2',
  },
  pocketworlds: {
    logoSrc: pocketworlds,
    name: 'Pocketworlds',
    url: 'https://www.pocketworlds.com/',
  },
  pontem: {
    logoSrc: pontem,
    name: 'Pontem',
    url: 'https://pontem.network/',
  },
  port: {
    logoSrc: port,
    name: 'Port Finance',
    url: 'https://port.finance/',
  },
  realms: {
    logoSrc: realms,
    name: 'Realms',
    url: 'https://realms.today/',
  },
  rome: {
    logoSrc: rome,
    name: 'Rome Terminal',
    url: 'https://rometerminal.io/',
  },
  scallop: {
    logoSrc: scallop,
    name: 'Scallop',
    url: 'https://medium.com/notifi/notifi-integrates-with-scallop-the-first-100m-tvl-defi-dapp-on-sui-5c6ff97339e8',
  },
  sharky: {
    logoSrc: sharky,
    name: 'Sharky',
    url: 'https://sharky.fi/',
  },
  snowflake: {
    logoSrc: snowflake,
    name: 'Snowflake',
    url: 'https://snowflake.so/',
  },
  spaceid: {
    logoSrc: spaceid,
    name: 'Space Id',
    url: 'https://space.id/',
  },
  struct: {
    logoSrc: struct,
    name: 'Struct',
    url: 'https://www.struct.fi/',
  },
  suins: {
    logoSrc: suins,
    name: 'Sui NS',
    url: 'https://suins.io/',
  },
  switchboard: {
    logoSrc: switchboard,
    name: 'Switchboard',
    url: 'https://switchboard.xyz/',
  },
  synfutures: {
    logoSrc: synfutures,
    name: 'SynFutures',
    url: 'https://www.synfutures.com/',
  },
  synthetix: {
    logoSrc: synthetix,
    name: 'Synthetix',
    url: 'https://synthetix.io/',
  },
  tashi: {
    logoSrc: tashi,
    name: 'Tashi',
    url: 'https://tashi.finance/',
  },
  thala: {
    logoSrc: thala,
    name: 'Thala Labs',
    url: 'https://www.thalalabs.xyz/',
  },
  thesolarmy: {
    logoSrc: thesolarmy,
    name: 'The Sol Army',
    url: 'https://www.thesolarmy.xyz/',
  },
  topaz: {
    logoSrc: topaz,
    name: 'Topaz',
    url: 'https://topaz.network/',
  },
  tradeport: {
    logoSrc: tradeport,
    name: 'Trade Port',
    url: 'https://www.tradeport.xyz/',
  },
  tribeca: {
    logoSrc: tribeca,
    name: 'Tribeca',
    url: 'https://tribeca.so/',
  },
  tsunami: {
    logoSrc: tsunami,
    name: 'Tsunami',
    url: 'https://tsunami.finance/',
  },
  ubiloan: {
    logoSrc: ubiloan,
    name: 'Ubiloan',
    url: 'https://ubiloan.io/ubiloan/',
  },
  vertex: {
    logoSrc: vertex,
    name: 'Vertex',
    url: 'https://vertexprotocol.com/',
  },
  vestafinance: {
    logoSrc: vestafinance,
    name: 'Vesta Finance',
    url: 'https://vestafinance.xyz/',
  },
  xion: {
    logoSrc: xion,
    name: 'Xion',
    url: 'https://vestafinance.xyz/',
  },
  xmtp: {
    logoSrc: xmtp,
    name: 'Xmtp',
    url: 'https://xmtp.org/',
  },
  zebec: {
    logoSrc: zebec,
    name: 'Zebec',
    url: 'https://zebec.io/',
  },
  zeroswap: {
    logoSrc: zeroswap,
    name: 'Zeroswap',
    url: 'https://zeroswap.io/',
  },
  zeta: {
    logoSrc: zeta,
    name: 'Zeta',
    url: 'https://www.zeta.markets/',
  },
};

type DappLookup = typeof DAPP_LOOKUP;
export type DappName = keyof DappLookup;
