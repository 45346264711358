import { Button, MenuItem, MenuList } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { eggshell, midnight } from 'theme/Theme';

import { ReactComponent as IconFoundationsAndValidators } from '../../assets/icn-foundations-validators.svg';
import { ReactComponent as IconLendingBrowsing } from '../../assets/icn-lending-browsing.svg';
import { ReactComponent as IconNFTAndGaming } from '../../assets/icn-nft-gaming.svg';
import { ReactComponent as IconPerpDEX } from '../../assets/icn-perp.svg';
import { ReactComponent as IconSpotDEX } from '../../assets/icn-spot.svg';
import { ReactComponent as IConStakingAndFarming } from '../../assets/icn-staking-farming.svg';
import { ReactComponent as IconWalletsAndInfra } from '../../assets/icn-wallets.svg';

const menuItemsStyle = {
  '&.Mui-selected': {
    backgroundColor: eggshell,
  },
  '&.Mui-selected:hover': {
    backgroundColor: eggshell,
  },
  '&:hover': {
    backgroundColor: eggshell,
  },
  borderRadius: '4px',
  fontFamily: 'RotaExtraBold',
  fontSize: '16px',
  gap: { sm: '8px', xs: '14px' },
  height: '40px',
  justifyContent: { sm: 'flex-start', xs: 'start' },
  margin: { sm: '0 12px 0 12px', xs: 0 },
  paddingLeft: '6px !important',
  px: { sm: 0, xs: 8 },
};

const iconStyle = { paddingBottom: '2px' };

type Props = {
  setOpen: (open: boolean) => void;
};

const MENUS = [
  {
    href: '/case-studies?tag=Lending',
    icon: <IconLendingBrowsing style={iconStyle} />,
    name: 'Lending & Borrowing',
  },
  {
    href: '/case-studies?tag=SpotDEX',
    icon: <IconSpotDEX style={iconStyle} />,
    name: 'Spot DEX',
  },
  {
    href: '/case-studies?tag=PerpDEX',
    icon: <IconPerpDEX style={iconStyle} />,
    name: 'Perp DEX',
  },
  {
    href: '/case-studies?tag=Staking',
    icon: <IConStakingAndFarming style={iconStyle} />,
    name: 'Staking & Farming',
  },
  {
    href: '/case-studies?tag=NFTs',
    icon: <IconNFTAndGaming style={iconStyle} />,
    name: 'NFTs & Gaming',
  },
  {
    href: '/case-studies?tag=Validators',
    icon: <IconFoundationsAndValidators style={iconStyle} />,
    name: 'Foundations & Validators',
  },
  {
    href: '/case-studies?tag=Wallets',
    icon: <IconWalletsAndInfra style={iconStyle} />,
    name: 'Wallets & Infra',
  },
];

export const MenuListStudies: React.FC<React.PropsWithChildren<Props>> = ({ setOpen }) => {
  const navigate = useNavigate();
  const handleNavigate = (href: string) => {
    setOpen(false);
    navigate(href);
  };
  return (
    <>
      <MenuList
        sx={{
          borderRadius: '4px',
          fontFamily: 'RotaExtraBold',
          fontSize: '16px',
          paddingTop: { sm: '12px', xs: '0' },
          pb: { sm: 0, xs: 2 },
          width: { sm: '390px', xs: 'unset' },
        }}
      >
        {MENUS.map(menu => (
          <MenuItem key={menu.name} onClick={() => handleNavigate(menu.href)} sx={menuItemsStyle}>
            {menu?.icon}
            <Button
              disableRipple
              sx={{
                '&:hover': {
                  background: eggshell,
                },
                background: 'transparent !important',
                borderRadius: 0,
                color: midnight,
                fontFamily: 'RotaExtraBold',
                fontSize: '16px',
                height: '100%',
                padding: 0,
                position: 'relative',
                textAlign: 'left',
              }}
            >
              {menu.name}
            </Button>
          </MenuItem>
        ))}
      </MenuList>
    </>
  );
};
