import { Link, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { heather } from 'theme/Theme';

type Props = {
  customsx?: SxProps;
};

const FooterInfoLinks: React.FC<Props> = ({ customsx }) => {
  const currentYear = new Date().getFullYear();
  return (
    <Stack
      direction='row'
      flexWrap={'wrap'}
      gap='40px'
      sx={{
        gap: {
          sm: '40px',
          xs: '12px',
        },
        justifyContent: {
          xs: 'flex-start',
        },
        padding: {
          sm: '4px 0',
          xs: '8px 30px',
        },
        width: '100%',
        ...customsx,
      }}
    >
      <Typography
        sx={{
          color: heather,
          fontFamily: 'RotaExtraBold',
          fontSize: '14px',
          fontWeight: 800,
          lineHeight: 'normal',
        }}
      >
        © {currentYear} Notifi Network, Inc
      </Typography>
      <Link
        href='/terms'
        sx={{
          color: heather,
          fontFamily: 'RotaExtraBold',
          fontSize: '14px',
          fontWeight: 800,
          lineHeight: 'normal',
          textDecoration: 'underline',
        }}
      >
        Terms of Service
      </Link>

      <Link
        href='/privacy'
        sx={{
          color: heather,
          fontFamily: 'RotaExtraBold',
          fontSize: '14px',
          fontWeight: 800,
          lineHeight: 'normal',
          textDecoration: 'underline',
        }}
      >
        Privacy Policy
      </Link>
    </Stack>
  );
};

export default FooterInfoLinks;
